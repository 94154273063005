import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

export const Map = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 45.76350114968823, lng: 21.255514452971155 }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 45.76350114968823, lng: 21.255514452971155 }} />}
    </GoogleMap>
));
